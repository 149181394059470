.version-info-box {
  margin: 15px 0;
  background-color: #fff;
  padding: 30px 20px;
  border-radius: 5px;
  text-align: left;
}

.version-info-box p {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #d5dadb;
}

.version-info-box p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.dark-mode .version-info-box p:last-child {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
  border-bottom: none !important;
}

.view-version-box {
  padding-left: 0px!important;
  padding-right: 0px!important;
  padding-bottom: 15px;
}