.navbar-brand .logo {
  width: 8rem;
  margin-right: 1rem;
}

@media screen and (min-width: 1200px) {
  .navbar-brand .logo {
    width: 10rem;
    padding: 0;
  }
}

.navbar-nav>li {
  margin-left: 10px !important;
  margin-right: 10px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.navbar {
  padding: 0rem 1rem !important;
}

.btn {
  color: white !important;
  /* border: 1px solid transparent; */
  border-radius: 25px !important;
  border-color: transparent !important;
}

.btn-primary {
  border-color: transparent;
}

.nav-item {
  font-weight: bold;
}

.navbar-nav>li {
  /* margin-left:15px; */
  margin-right: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.user-icon {
  width: auto;
  height: auto;
}

.banner {
  background-color: #FDFDEA;
  color: black;
  font-size: 16px;
  padding: 10px;
}

#dropdown-team-switch {
  all: unset !important;
  cursor: pointer !important;
}

.dropdown-item:hover {
  color: #16181b !important;
  text-decoration: none !important;
  --tw-bg-opacity: 1;
  background-color: rgba(246, 245, 255, var(--tw-bg-opacity)) !important;
}

.hover-danger-text:hover {
  --tw-text-opacity: 1;
  color: rgba(200, 30, 30, var(--tw-text-opacity)) !important;
}

.danger-hover:hover {
  text-decoration: none !important;
  --tw-bg-opacity: 1;
  background-color: rgba(253, 232, 232, var(--tw-bg-opacity)) !important;
}

.team-name-ellipsis {
  display: inline-block;
  max-width: 40vw;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dropdown-team-toggle::after {
  vertical-align: 0.55em !important;
}