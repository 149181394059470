.activity-table tr {
    white-space: nowrap !important;
}

.interaction-table tr {
    white-space: nowrap !important;
}

.empty-container {
    text-align: center !important;
}

.purple-button {
    background-color: #9061F9 !important;
    border: none !important;
}

.activity-table table td {
    max-width: 40ch !important;
}