.empty-container {
    height: 100%;
    position: relative;
    overflow: hidden;
}

.empty-text {
    font-size: 22px;
    /* color: grey; */
    text-align: center;
    /* position: absolute; */
    top: 0;
    /* bottom: 0; */
    left: 0;
    right: 0;
    height: fit-content;
    margin: auto;
}