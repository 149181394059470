.project-area nav li {
  color: black;
  cursor: pointer;
  font-weight: 600;
}

.project-area nav .active {
  color: red;
}

/* .project-area nav .active::after {
  content: "";
  width: 80%;
  margin: 0 auto;
  height: 2px;
  background-color: rgb(255, 69, 101);
  display: block;
} */
.project-area .card {
  /* border: none; */
}
.project-area .card-img-top {
  width: 60%;
  display: block;
  padding-top: 10px;
  margin: 0 auto;
}

.project-area div > a {
  color: #000;
}

.project-area div > a:hover {
  text-decoration: none;
}

.project-area .card:hover {
  box-shadow: 0 5px 13px 0px #eae8e8;
}

.intro-container {
  padding-bottom: 25px;
}

.new-project-button {
  /* height: 25px;
    padding-top: 5px!important; */
}

.project-heading {
  text-align: left;
}

.project-heading p {
  font-weight: bold;
  font-size: 22px;
  margin-top: -10px !important;
  margin-bottom: 0rem !important;
}

.project-heading span {
  font-weight: normal;
  font-size: 16px;
}

.btn-primary {
  border-color: transparent !important;
}
