.form {
  padding: 20px;
  background-color: white;
  text-align: left;
}

.cancel-button {
  all: unset !important;
  cursor: pointer !important;
  padding: 10px !important;
  /* padding-right: 20px; */
}

.project-key-box {
  white-space: initial;
  word-wrap: break-word;
  margin: 0px !important;
  padding: 5px !important;
}
