.shadow {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow) !important;
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow) !important;
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
}

.project-menu {
  margin: -25px;
  font-size: 20px;
  float: right;
  color: darkslategray;
}

.api-button {
  background-color: #7E81F5 !important;
}

.project-card {
  padding: 30px 30px !important;
  min-height: 300px;
  background-color: #fff!important;
}

.project-text-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.project-card-padding {
  padding: 1rem!important;
}