.bg-landing-page {
  background-color: #121826 !important;
}

.landing-page-section {
  padding-top: 30px;
  padding-bottom: 50px;
  position: relative;
  text-align: left;
}

.text-white {
  color: #eeeeee;
}

.landing-muted-text {
  --tw-text-opacity: 1;
  color: rgb(147 157 184 / var(--tw-text-opacity));
}

.text-landing-h1 {
  font-size: 3rem;
  line-height: 130%;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 150%;
}

.cover {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow) !important;
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow) !important;
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  border-radius: 0.5rem;
}

.feature-text-color {
  color: #161E2E !important;
}

.sdk-card {
  --tw-bg-opacity: 1;
  background-color: rgb(34 40 56 / var(--tw-bg-opacity));
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  text-align: left;
  transition: border 0.2s;
  transition: box-shadow 0.1s;
}

.customer-card {
  position: relative;
  overflow: hidden;
  text-align: left;
}

.customer-card:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(34 40 56 / var(--tw-bg-opacity));
}

.y-l2 {
  background-image: url(https://transform.storyasset.link/website-images/path2.svg);
  background-position: left 1rem;
  padding-bottom: 1rem;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.testimonial-card {
  padding: 30px 40px;
  --tw-bg-opacity: 1;
  background-color: rgb(34 40 56 / var(--tw-bg-opacity));
  position: relative;
  border-radius: 0.5rem;
  overflow: hidden;
  text-align: left;
}

.testimonial-card.featured {
  --tw-bg-opacity: 1;
  background-color: rgb(34 40 56 / var(--tw-bg-opacity));
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  margin-top: 50px;
}

.text-muted {
  color: #41484d !important;
}

.sdk-background {
  background: linear-gradient(0deg, #192555, #493970);
}

.demo-background {
  --tw-bg-opacity: 1;
  background-color: rgba(19, 25, 39, var(--tw-bg-opacity));
}

.footer-background {
  background: linear-gradient(0deg, #121b3e, #3a2d59);
}

.blurGradientBackground {
  background: linear-gradient(152deg, #ecb4f0, #ad97f7 50%, #95d5f8);
  background-size: cover;
  -moz-filter: blur(45px);
  -ms-filter: blur(45px);
  -o-filter: blur(45px);
  filter: blur(45px);
  width: --webkit-fill-available;
  height: 100px;
  position: 100%;
  border-radius: 9999px;
  opacity: .7;
}

.glow-shadow {
  box-shadow: 0 4px 15px -1px #7d82ff4a !important;
  border-radius: 10px;
}

.glowing-box-shadow {
  border: 1px solid #7d82ff70 !important;
  box-shadow: 0 0px 10px #7d82ff4a !important;
}

.glowing {
  box-shadow: 0 0px 15px #7d82ff4a !important;
}

.list-unstyled {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-weight: 600;
}

.changelog-container {
  max-width: 42rem;
  margin: 0 auto;
}

.changelog-text {
  color: #37352f;
  font-size: 16px;
}

.changelog-badge {
  background-color: #eeeeee;
  color: #8b919c;
  display: block !important;
  font-size: 100% !important;
  width: max-content;
  min-width: 60px;
  margin: 0px !important;
  border-radius: 100px !important;
  padding: 0.5rem 1rem !important;
}

.dark-mode .changelog-badge {
  background-color: #222838 !important;
  color: #ffffff !important;
}

.changelog-feature {
  background-color: #ddedea;
  color: #ffffff;
}

.landing-page-section a {
  --tw-text-opacity: 1;
  color: #7d82ff !important;
}

.footer a {
  --tw-text-opacity: 1;
  color: rgb(147 157 184 / var(--tw-text-opacity)) !important;
}

.footer a:hover {
  color: #fff !important;
}

.hero a {
  color: #fff !important;
}

.hero a:hover {
  color: #fff !important;
}

.contact a {
  color: #fff !important;
}

.contact a:hover {
  color: #fff !important;
}

.sdk-card .card-body-hover:hover img {
  filter: none !important;
}

.sdk-card .card-body-hover-invert-full:hover img {
  filter: invert(1) !important;
}

.sdk-card:hover {
  border: 1px solid #7d82ff70 !important;
  box-shadow: 0 0px 24px #7d82ff4a !important;
}

.benefits-card-details {
  background: #181e2c;
  padding: 20px;
  border-radius: 0.5rem;
  border: 1px solid #7d82ff70;
}

.benefits-card-details:hover {
  box-shadow: 0 0px 20px #7d82ff4a !important;
}

.radial-bg {
  background: radial-gradient(45.47% 45.47% at 50% 122.03%, #7d82ff1f 0%, rgba(34, 40, 56, 0) 100%);
  transform: translate(0, 0) rotate(180deg) skewX(0) skewY(0) scaleX(1) scaleY(1);
  /* z-index: -10; */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.loading-bg {
  background: #121826;
  height: 100vh;
}

.radial-bg-up {
  background: radial-gradient(45.47% 45.47% at 50% 122.03%, #7d82ff1f 0%, rgba(34, 40, 56, 0) 100%);
  transform: translate(0, 0) rotate(0deg) skewX(0) skewY(0) scaleX(1) scaleY(1);
  /* z-index: -10; */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.mt-20 {
  margin-top: 4rem;
}

@media (min-width: 576px) {
  .mt-20 {
    margin-top: 4rem !important;
  }
}

@media (min-width: 768) {
  .mt-20 {
    margin-top: 4rem !important;
  }
}

@media (min-width: 992) {
  .mt-20 {
    margin-top: 7rem !important;
  }
}

@media (min-width: 1200px) {
  .mt-20 {
    margin-top: 7rem !important;
  }
}

@media (max-width: 640px) {
  .text-landing-hero {
    font-size: 3.5rem;
    line-height: 1.2;
  }
}

@media (min-width: 640px) {
  .text-landing-hero {
    font-size: 4.5rem;
    line-height: 1;
  }
}

.plain-button {
  border: none !important;
  padding: 5px 10px 5px 10px !important;
  background-color: transparent !important;
}

.checkout-active-button {
  background-color: #7d82ff !important;
  border: none !important;
  padding: 5px 10px 5px 10px !important;
}

.plain-button:focus {
  box-shadow: none !important;
}

.btn:focus {
  box-shadow: none !important;
}

.cta-card {
  background: rgb(34 40 56 / 1);
  padding: 50px;
  border-radius: 1rem;
}

.link-arrow {
  font-size: large;
  display: inline !important;
  color: black !important;
}

.btn-cta {
  border: 1px solid #ffffff30 !important;
  color: #9061F9 !important;
  border-radius: 9999px !important;
  width: 140px !important;
  border-color: #ffffff30 !important;
}

.btn-cta:hover {
  color: #eee !important;
  -webkit-animation: animateBg 2s ease infinite alternate;
  animation: animateBg 2s ease infinite alternate;
  background-size: 200% 300%;
  transform: scale(1.06);
}

.cta {
  padding-top: 0px !important;
}

.cta a {
  color: white !important;
}

.cta .cta-card a {
  color: #9061F9 !important;
}

.markets {
  padding: 0px !important;
}

.mt-10 {
  margin-top: 3rem !important;
}

.animate-spin {
  animation: spin 10s infinite;
  transform-origin: 50% 50%;
  transform-box: fill-box;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

.animate-pulse {
  animation: pulse 2s infinite;
  transform-origin: 50% 50%;
  transform-box: fill-box;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

.pulse-1-2 {
  animation: pulse-1-2 2s infinite !important;
}

.app-android {
  animation: pulse-android 2s infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    transform: scale(1.5);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes pulse-1-2 {
  0% {
    opacity: 1;
  }

  50% {
    transform: scale(1.2);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes pulse-android {
  0% {
    opacity: 1;
    transform: scale(1.2);
  }

  50% {
    transform: scale(1.8);
    opacity: 1;
  }

  100% {
    transform: scale(1.2);
    opacity: 1;
  }
}

.sdk-appupgrade {
  stroke-dasharray: 2600;
  stroke-dashoffset: 2600;
  animation: draw 1s linear forwards;
}

.sdk-store {
  stroke-dasharray: 2600;
  stroke-dashoffset: 2600;
  animation: draw 4s linear forwards;
}


@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

.outline-text {
  text-align: center;
  border: 1px solid #ffffff15;
  border-radius: 9999px;
  padding: 0.2rem 0.8rem;
  display: inline-block;
  font-weight: 600;
  margin: 0;
}

.outline-shadow {
  box-shadow: 0 0px 1px #FFFFFF !important
}

/** Animations */
.reveal-effect {
  animation: 1200ms ease 0ms 1 normal backwards running reveal;
}

.reveal-effect-built {
  animation: 1200ms ease 100ms 1 normal backwards running reveal;
}

.reveal-effect-hero-text {
  animation: 1200ms ease 200ms 1 normal backwards running reveal;
}

.reveal-effect-hero-description {
  animation: 1200ms ease 400ms 1 normal backwards running reveal;
}

.reveal-effect-hero-cta {
  animation: 1200ms ease 600ms 1 normal backwards running reveal;
}

.reveal-effect-flow {
  animation: 1200ms ease 800ms 1 normal backwards running reveal;
}

@keyframes reveal {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

.landing-loading-skeleton {
  background-color: #121826 !important;
  --base-color: #121826 !important;
  --highlight-color: #222838 !important;
}

.svg-component {
  position: relative;
  z-index: 2;
}

.svg-compoent-backdrop {
  filter: blur(50px);
  opacity: 0.9;
  z-index: -1;
  inset: 0;
  position: absolute;
  background: radial-gradient(50% 50% at 50% 50%, #24346a, #121826);
}