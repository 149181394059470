body{margin-top:20px;}
.section-title {
    position: relative;
}
.section-title h2 {
    color: #1d2025;
    position: relative;
    margin: 0;
    font-size: 24px;
}
@media (min-width: 768px) {
    .section-title h2 {
        font-size: 28px;
    }
}
@media (min-width: 992px) {
    .section-title h2 {
        font-size: 34px;
    }
}
.section-title.title-ex1 h2 {
    padding-bottom: 20px;
}
@media (min-width: 768px) {
    .section-title.title-ex1 h2 {
        padding-bottom: 30px;
    }
}
@media (min-width: 992px) {
    .section-title.title-ex1 h2 {
        padding-bottom: 40px;
    }
}
.section-title.title-ex1 h2:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 12px;
    width: 110px;
    height: 1px;
    background-color: #d6dbe2;
}
@media (min-width: 768px) {
    .section-title.title-ex1 h2:before {
        bottom: 17px;
    }
}
@media (min-width: 992px) {
    .section-title.title-ex1 h2:before {
        bottom: 25px;
    }
}
.section-title.title-ex1 h2:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 12px;
    width: 120px;
    height: 2px;
    background: linear-gradient(-60deg,#cb8bcf,#825ef5,#1ea0e8);
}

@media (min-width: 768px) {
    .section-title.title-ex1 h2:after {
        bottom: 17px;
    }
}
@media (min-width: 992px) {
    .section-title.title-ex1 h2:after {
        bottom: 25px;
    }
}
.section-title.title-ex1.text-center h2:before {
    left: 50%;
    transform: translateX(-50%);
}
.section-title.title-ex1.text-center h2:after {
    left: 50%;
    transform: translateX(-50%);
}
.section-title.title-ex1.text-right h2:before {
    left: auto;
    right: 0;
}
.section-title.title-ex1.text-right h2:after {
    left: auto;
    right: 0;
}
.section-title.title-ex1 p {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 300;
}


.price-card {
    --tw-bg-opacity: 1;
    background-color: rgb(34 40 56 / var(--tw-bg-opacity));
    padding: 70px 50px;
    position: relative;
    border-radius: 20px;
    /* overflow: hidden; */
    text-align: left;
    border-color: rgba(172,148,250,var(--tw-border-opacity));
}

.price-card-recommended:hover {
    /* border: 2px solid !important;
    border-color: rgba(172,148,250,0.9) !important; */
    border: 1px solid #7d82ff70 !important;
    box-shadow: 0 0px 10px #7d82ff4a !important;
}

.transform {
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-translate-x: 0;
    --tw-translate-y: -50%;

    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    position: absolute;
    top: 0;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 9999px;
    right: 1rem;
    color: white;
    font-weight: bold;
}
/* .price-card:before {
    position: absolute;
    content: "";
    top: 0;
    right: -35px;
    width: 88px;
    height: 88px;
    background: #eb1e5b;
    opacity: 0.2;
    border-radius: 20px;
    transform: rotate(45deg);
} */
/* .price-card:after {
    position: absolute;
    content: "";
    top: 30px;
    right: -35px;
    width: 88px;
    height: 88px;
    background: #eb1e5b;
    opacity: 0.2;
    border-radius: 20px;
    transform: rotate(45deg);
} */
.price-card h2 {
    font-size: 26px;
    font-weight: 700;
}
.price-card .btn {
    font-size: 16px;
    font-weight: bold;
    border-radius: 100px;
    padding: 0 25px;
    border: 0;
    color: #fff;
    float: left;
}
.price-card .btn.btn-primary {
    border: 0 !important;
    width: -webkit-fill-available
}
.price-card.featured {
    --tw-bg-opacity: 1;
    background-color: rgb(34 40 56 / var(--tw-bg-opacity));
    /* border: 1px solid #ebebeb; */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}
.price-card.subscription {
    background-color: #fff;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}
/* .price-card:hover .btn {
    background: #eb1e5b;
    border-color: #eb1e5b;
} */
p.price span {
    display: inline-block;
    padding: 0px 15px 0px;
    padding-right: 0;
    font-size: 50px;
    font-weight: bold;
    color: black;
    position: relative;
}
p.price span:before {
    position: absolute;
    content: "$";
    font-size: 16px;
    top: 25px;
    font-weight: 300;
    left: 0;
}
.pricing-offers {
    padding: 0 0 0px;
    text-align:  left;
}
.pricing-offers li {
    padding: 0 0 16px;
    line-height: 18px;
}
ul li {
    list-style-type: none;
}
.btn.btn-mid {
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
}

.pricing-switch {
    border: 1px solid #7d82ff;
    border-radius: 9999px;
    justify-content: space-between;
    width: 140px;
    margin: 0 auto;
    display: flex;
}

.btn-full {
    width: 100%;
}

.price-card-top-padding {
    padding-top: 25px;
}