html,
body {
  background-color: #121826 !important;
}

.App {
  text-align: center;
  background-color: #121826;
  background-size: cover;
  font-size: 0.9rem !important;
  min-height: 100vh;
}

.light-mode .App {
  background-color: #eaeef6 !important;
}

.dark-mode .error {
  color: red !important;
}

@font-face {
  font-display: swap;
}

.light-purple-gradient {
  background: radial-gradient(54.78% 805% at 50% 50%, #C7C9FF 0%, #7D82FF 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text-accent {
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-image: linear-gradient(-60deg, #cb8bcf, #825ef5, #1ea0e8);
}

.gradient-bg-accent {
  background: linear-gradient(-60deg, #7D82FF, #825ef5, #825ef5);
}

.gradient-border-accent {
  border-image-source: linear-gradient(-60deg, #cb8bcf, #825ef5, #1ea0e8);
  border-image-slice: 1;
}

.bg-purple {
  --tw-bg-opacity: 1;
  background-color: rgba(246, 245, 255, var(--tw-bg-opacity));
}

.text-purple {
  color: #7d82ff !important;
}

.ai {
  color: #7d82ff !important;
}

.dark-mode .ai {
  color: #7d82ff !important;
}

.text-cool-gray-600 {
  color: rgba(71, 85, 105, 1) !important;
  opacity: 0.7;
}

.text-nav {
  color: rgba(0, 0, 0, .5);
}

.text-cool-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  opacity: .7;
}


.text-cool-black {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
  opacity: .9;
}

.dark-mode .text-cool-gray-600 {
  color: white !important;
}

.gradient-bg-accent:hover {
  background-size: 200% 300%;
  transform: scale(1.06);
}

.gradient-bg-accent-no-hover {
  background: linear-gradient(-60deg, #cb8bcf, #825ef5, #1ea0e8);
}

.section-hero {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.dark-mode {
  background-color: #121826 !important;
}

.dark-mode .App {
  background-color: #121826 !important;
  background-image: none;
}

.dark-mode li a {
  color: #fff !important;
}

.dark-mode p {
  --tw-text-opacity: 1;
  color: rgb(147 157 184 / var(--tw-text-opacity)) !important;
}

.dark-mode h1 {
  color: #fff !important;
}

.dark-mode h2 {
  color: #fff !important;
}

.dark-mode h3 {
  color: #fff !important;
}

.dark-mode h4 {
  color: #fff !important;
}

.dark-mode h5 {
  color: #fff !important;
}

.dark-mode h6 {
  color: #fff !important;
}

.dark-mode span {
  --tw-text-opacity: 1;
  color: rgb(147 157 184 / var(--tw-text-opacity)) !important;
}

.dark-mode label {
  color: #fff !important;
}

.dark-mode section {
  background-color: #121826 !important;
}

.dark-mode .bg-white {
  background-color: #222838 !important;
}

.dark-mode .bg-light {
  background-color: #222838 !important;
}

.dark-mode form,
form-control {
  background-color: #222838 !important;
  border: none !important;
}

.dark-mode .form {
  background-color: #222838 !important;
  border: none !important;
}

.dark-mode .form-control {
  color: #eeeeee;
  background-color: #121826 !important;
}

.dark-mode .project-card {
  color: white !important;
  background-color: #222838 !important;
  border: #121826 !important;
}

.dark-card {
  color: white !important;
  background-color: #222838 !important;
  border: #121826 !important;
  padding: 30px 30px !important;
}

.dark-bg {
  color: white !important;
  background-color: #222838 !important;
}

.dark-mode .subscription {
  color: white !important;
  background-color: #222838 !important;
  border: #121826 !important;
}

.dark-mode .price-card form {
  border: none !important;
}

.dark-mode table td {
  background-color: #222838 !important;
  border-top: 1px solid #121826 !important;
  border-bottom: 1px solid #121826 !important;
}

.dark-mode table th {
  background-color: #222838 !important;
  border-top: 1px solid #121826 !important;
  border-bottom: 1px solid #121826 !important;
}

.dark-mode .project-heading p {
  color: #fff !important;
}

.dark-mode .version-heading p {
  color: #fff !important;
}

.dark-mode .upgrade-toggle p {
  color: #688df7 !important;
}

.dark-mode .api-button span {
  color: #fff !important;
}

.dark-mode .dropdown-toggle span {
  color: #fff !important;
}

.dark-mode .landing-page-section span {
  color: #fff !important;
}

.dark-mode .footer a {
  --tw-text-opacity: 1;
  color: rgb(147 157 184 / var(--tw-text-opacity)) !important;
}

.dark-mode .footer a:hover {
  color: #fff !important;
}

.dark-mode .swal2-popup {
  background-color: #222838;
}

.dark-mode .banner {
  background-color: #152732;
  color: black;
  font-size: 16px;
  padding: 10px;
}

.dark-mode .alternate-login-span {
  background: #222838;
}

.dark-mode .google-login-button {
  background-color: rgb(15 15 15) !important;
}

.dark-mode .google-login-button>div {
  background: rgb(15 15 15) !important;
}

.dark-mode .cancel-button {
  color: white !important;
}

.dark-mode .footer-divider {
  --tw-text-opacity: 1;
  color: rgb(147 157 184/var(--tw-text-opacity));
}

.dark-mode .upgrade-toggle {
  background-color: #516ec324 !important;
  border: 1px solid #516ec328 !important;
  color: #516ec3 !important;
}

.dark-mode .version {
  background-color: #99999924 !important;
  border: 1px solid #99999928 !important;
}

.purple-button {
  background-color: #9061f9 !important;
  border: none !important;
}

.dark-mode .version-info-box {
  background-color: #222838
}

.dark-mode .version-info-box p {
  border-bottom: 1px solid #121826 !important;
}

.dark-mode .edit-button {
  color: white
}

.dark-mode .insight-card {
  background: #222838 !important;
}

.dark-mode .react-loading-skeleton {
  background-color: #121826 !important;
  --base-color: #121826 !important;
  --highlight-color: #222838 !important;
}

.nav-link {
  color: rgba(0, 0, 0, .5);
}

.nav-link:hover {
  color: rgba(0, 0, 0, .7);
}

.dark-mode .nav-dark {
  color: #fff !important;
}

.dark-mode .nav-link {
  color: #eeeeee !important;
}

.dark-mode .nav-link:hover {
  color: #7D82FF !important;
}

.dark-mode .nav-link.active {
  color: #7D82FF !important;
  background-color: #222838 !important;
  /* border-color: #dee2e6 #dee2e6 #fff !important; */
  border: none !important;
}

.nav-tabs .nav-link:hover {
  border: none !important;
}

.nav-tabs {
  border: none !important;
}

.nav-tabs .nav-link {
  border-bottom-left-radius: 9999px !important;
  border-top-left-radius: 9999px !important;
  border-top-right-radius: 9999px !important;
  border-bottom-right-radius: 9999px !important;
}

.dark-mode .dropdown-menu {
  background: #222838 !important;
}

.dark-mode .dropdown-item:hover {
  background: #121826 !important;
  color: #fff !important;
}

.dark-mode .member-badge {
  color: white !important;
}

.dark-mode .bg-purple {
  background: #222838 !important;
}

.dark-mode .text-cool-black {
  color: #fff !important;
}

.dark-mode .bg-purple-dark {
  background: #12182636 !important;
}

.dark-mode .tippy-box {
  background-color: #222838 !important;
}

a:hover {
  /* color: white !important; */
  text-decoration: none !important;
}

.dropdown-toggle::after {
  color: #222838 !important;
}

.dark-mode .dropdown-toggle::after {
  color: #fff !important;
}

.form-control:focus {
  box-shadow: 0 0 0 0.1rem #7d82ff !important;
}

.dark-mode .form-control:focus {
  color: #fff;
  background-color: #222838;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.1rem #7d82ff;
}

.dark-mode .form-control {
  border: none !important;
  background-color: #121826 !important;
  border: 1px solid #222838 !important;
}

.form-control {
  border: none !important;
  background-color: #eaeef6 !important;
  border: 1px solid #ced4da !important;
}

.api-box {
  background-color: #eaeef6 !important;
}

.dark-mode .api-box {
  background-color: #121826 !important;
}

.dropdown-divider-custom {
  border-top: 1px solid transparent !important;
}

.dark-mode .dropdown-divider {
  border-top: 1px solid #121826 !important;
}

.text-align-right {
  text-align: right !important;
}

.dark-mode .swal2-html-container {
  color: #fff !important;
}