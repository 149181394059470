button {
  all: unset;
  cursor: pointer;
  padding: 5px;
}

.edit-button {
  color: blue;
}

.delete-button {
  color: red;
}

.intro-container {
  padding-bottom: 25px;
}

.new-version-button {
  /* height: 25px;
  padding-top: 5px!important; */
}

.version-heading {
  text-align: left;
}

.version-heading p {
  font-weight: bold;
  font-size: 22px;
  margin-top: -10px !important;
  margin-bottom: 0rem !important;
}

.version-heading span {
  font-weight: normal;
  font-size: 16px;
}

.pagination-container {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pagination-button {}

.platform-logo-ios {
  height: 25px;
  width: auto;
  filter: invert(0.6)
}

.platform-logo-android {
  height: 16px;
  width: auto;
}

.empty-screen {
  width: 100%;
  height: 100%;
}

.version {
  background-color: #eeeeee;
  color: #8b919c;
  display: block !important;
  font-size: 100% !important;
  width: max-content;
  min-width: 60px;
  margin: 0 auto !important;
  border-radius: 100px !important;
}

/* .environment {
  color: #516ec3;
  background-color: #dbe9fe;
  display: block!important;
  font-size: 100%!important;
  width: max-content;
  margin: 0 auto;
} */

.upgrade-toggle {
  color: #516ec3;
  background-color: #dbe9fe;
  display: block !important;
  font-size: 100% !important;
  margin: 0 auto;
  width: max-content;
  min-width: 60px;
  border-radius: 100px !important;
}

.badge p {
  padding: 2px;
  margin: 0px;
}

.version-table-action-button {
  display: inline-flex;
}

.version-table tr {
  white-space: nowrap !important;
}

.version-table-options {
  /* background-color: #fff; */
  padding: 10px 10px;
  text-align: right !important;
}

.form-row-version {
  flex-wrap: nowrap !important;
}

.table thead th {
  text-align: center;
}

.table th:nth-child(1),
td:nth-child(1) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 10ch;
  text-align: left !important;
}

.table th:nth-child(2),
td:nth-child(2) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 24ch;  
  text-align: left !important;
}

.table th:nth-child(5),
td:nth-child(5) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 20ch;
  text-align: left !important;
}

.caret-off::before {
  display: none !important;
}

.caret-off::after {
  display: none !important;
}

.menu-link {
  display: block;
  padding: 0.6rem 0.4rem;
  color: #8b919c;
  text-decoration: none !important;
  cursor: pointer;
  width: 6rem;
  font-weight: 500;
}

.menu-link-danger {
  color: #8b919c !important;
}

.menu-link:hover span {
  color: #7D82FF !important;
}

.menu-link-danger:hover span {
  color: #ff0000b5 !important;
}

.dark-mode .menu-link:hover {
  color: #7D82FF !important;
}

.dark-mode .menu-link-danger:hover {
  color: #ff0000b5 !important;
}

.tippy-box {
  position: relative;
  background-color: #fff !important;
  color: #fff;
  border-radius: 4px;
  padding: 0.3rem 0.3rem !important;
  color: #8b919c;
  font-weight: 400 !important;
  font-size: 0.9rem !important;
  text-decoration: none !important;
  outline: 0;
  transition-property: transform, visibility, opacity;
  text-align: left;
  background-clip: padding-box;
  border: 1px solid rgba(0, 8, 16, .15);
  color: #333;
  box-shadow: 0 4px 14px -2px rgb(0 8 16 / 8%);
}

.tooltip-tippy {
  width: max-content !important;
}

.tippy-content {
  position: relative;
  padding: 0px 0px;
  z-index: 1;
}

.dot-menu:hover {
  color: #9061f9;
}

.text-danger-100:hover {
  color: red;
}

.status-switch input {
  display: none;
}

.status-switch span {
  position: absolute;
  background-color: #dc851c24;
  border-radius: 100px;
  border: 1px solid #dc851c28;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.status-switch {
  position: relative;
  width: 90px;
  height: 25px;
  display: inline-block;
  cursor: pointer;
  margin-bottom: 0rem !important;
}

.status-switch input {
  display: none;
}

.status-switch span::before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #dc851c;
  top: 4px;
  left: 5px;
  transition: .8s;
  z-index: 999;
}

.status-switch span::after {
  content: "Inactive";
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 12px;
  transform: translate(-50%, -50%);
  font-weight: 700;
  color: #dc851c;
}

.status-switch input:checked+span {
  background-color: #58b29924;
  border: 1px solid #58b29928;
}

.status-switch input[type="checkbox"]:checked+span::before {
  transform: translateX(65px);
  background-color: #58b299;
}

.status-switch input[type="checkbox"]:checked+span::after {
  content: "Active";
  color: #58b299;
}