* {
  box-sizing: border-box !important;
}

.sign-up {
  background-color: #121826 !important;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom;
  height: 120vh;
  box-sizing: content-box;
}

.sign-up .logo img {
  height: 80px;
}

.sign-up form {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background: #222838;
}

.sign-up .form-control {
  background: #121826 !important;
  border: none!important;
  padding: 25px 15px !important;
}

.sign-up .form-control:focus {
  color: #eeeeee!important;
  background: #121826 !important;
  border: none!important;
  padding: 25px 15px !important;
  box-shadow: 0 0 0 0.1rem #7d82ff;
}

.error {
  color: red !important;
  margin-bottom: 1rem;
}

.sign-up .option label {
  color: #7d82ff;
  cursor: pointer;
}

.alternate-login {
  background-image: linear-gradient(90deg, #e4e8ed 33%, #e4e8ed 0);
  background-position: bottom;
  background-size: 1px 1px;
  background-repeat: repeat-x;
  text-align: center;
  height: 15px;
  margin-bottom: 35px;
  margin-top: 25px;
}

.alternate-login-span {
  background: #222838;
  padding: 0 10px;
  color: #aaa;
  font-size: 14px;
  font-weight: 500;
}

.google-login-button {
  box-shadow: none !important;
  width: 100%;
  justify-content: center;
  background-color: rgb(245 245 245) !important;
  border-radius: 20px !important;
  border: none !important;
}

.google-login-button > div {
  background-color: rgb(245 245 245) !important;
}