.form {
  padding: 20px;
  background-color: white;
  text-align: left;
  font-weight: 500;
}

.form-check {
  padding-left: 1.25rem !important;
}

.cancel-button {
  all: unset !important;
  cursor: pointer !important;
  padding: 10px !important;
  /* padding-right: 20px; */
  color: black !important;
}

input[type=checkbox], input[type=radio] {
  transform: scale(1.5);
}

.vs-tooltip-content {
  border-bottom: 2px dashed rgba(70, 85, 106, 0.2);
}

.form-check-input {
  position: absolute;
  margin-top: 0.5rem !important;
  margin-left: -1.25rem;
}

.new-badge {
  color:white;
}

.underline-button {
  cursor: pointer;
  border: none;
  border-bottom: 1px solid rgb(147 157 184 / 1); /* Change the color as needed */
  background-color: transparent;
  color: rgb(147 157 184 / 1); /* Change the color as needed */
  padding: 0;
}

.underline-button:hover {
  background-color: transparent;
  color: rgb(147 157 184 / 1); /* Change the hover color as needed */
  border-bottom: 1px solid rgb(147 157 184 / 1); /* Change the hover color as needed */
}