.account-components {
    padding-top: 40px;
    padding-bottom: 15px;
}

.nav {
    font-weight: 700 !important;
}

.plan {
    padding: 25px;
    /* width: 40%; */
}

.card-item {
    padding: 10px;
}

.account-details p {
    font-weight: normal !important;
    font-size: 16px;
    margin-top: 0px !important;
    margin-bottom: 1rem !important;
}

.helper-box {
    margin: 15px 0;
    padding: 30px 20px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    text-align: left;
    margin-right: 0px;
    margin-left: 0px;
}

.version-info-box-2 {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.member-table .table {
    margin-bottom: 0px !important;
    text-align: center !important;
}

.member-table {
    margin-top: -2rem !important;
}